<template>
	<div class="main-wrapper mt-4">
		<CRow>
			<CCol md="12">
				<CInput
					v-model="$v.formData.name.$model"
					:disabled="isEditAbleName"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Group name*"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model="$v.formData.description.$model"
					label="description"
				/>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="canRemove"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
			@onRemove="handleRemove"
		/>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'FormCustomerGroup',
	validations: {
		formData: {
			name: {
				required,
			},
			description: {},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		isDeletable: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formData: {
				name: null,
				description: null,
			},
		};
	},
	computed: {
		canRemove() {
			return this.isDeletable ? 'Remove group' : '';
		},
		isEditAbleName() {
			return this.isEdit && !this.isDeletable;
		},
	},
	created() {
		this.formData = {
			name: this.name,
			description: this.description,
		};
	},
	methods: {
		handleCancel() {
			this.$router.push({ name: 'CustomerGroupList' });
		},
		handleConfirm() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				name: this.formData.name,
				description: this.formData.description,
			};

			this.$emit('onConfirm', params);
		},
		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>
